namespace Shared.EditorTemplates.AgentIdSeach
{
    var serviceURL: string;

    var $divAgentIdSearch: JQuery;

    var $spanAgentIdSearchResults: JQuery;

    var searchRequest: JQueryXHR;

    const documentReady = () =>
    {
        serviceURL = virtualDir + 'api/Agents/Search';
        $divAgentIdSearch = $(searchDiv).appendTo(document.body);
        $spanAgentIdSearchResults = $("#spanAgentIdSearchResults");
        $("#btnAgentIdSearchClose").on('click', btnAgentIdSearchClose);
        $("#txtAgentIdSearchTerm").on('input', txtAgentIdSearchTermInput);

        setupControls();
    }

    const txtAgentIdSearchTermInput = () =>
    {
        searchAgents();
    }

    const btnAgentIdSearchClose = () =>
    {
        $divAgentIdSearch.hide();
    }

    const documentAJAXComplete = () =>
    {
        setupControls();
    }

    const setupControls = () =>
    {
        var $agentIdSearchNameInputs = $("input[data-elementtype='agentIdSearchNameInput']:not(.agentIdSearchNameInputClick)");
        $agentIdSearchNameInputs.on("click", agentIdSearchNameInputClick);
        $agentIdSearchNameInputs.addClass("agentIdSearchNameInputClick");
    }

    const agentIdSearchNameInputClick = (event: JQueryEventObject) =>
    {
        var group = $(event.target).data("group");

        $divAgentIdSearch.data("group", group);

        $divAgentIdSearch.show();

        $("#txtAgentIdSearchTerm").focus();

        searchAgents();
    }

    const searchAgents = () =>
    {
        $spanAgentIdSearchResults.html("Searching...");

        var searchTerm = $("#txtAgentIdSearchTerm").val();

        var actionUrl = `${serviceURL}?searchTerm=${searchTerm}`;

        if (searchRequest)
        {
            searchRequest.abort();
        }

        searchRequest = $.ajax(
            <JQueryAjaxSettings>{
                type: "GET",
                url: actionUrl,
                dataType: 'json',
                cache: false,
                success: (data: any, textStatus: string, jqXHR: JQueryXHR) => searchAgentsSuccess(data),
                error: (jqXHR: JQueryXHR, textStatus: string, errorThrown: string) => searchAgentsError(textStatus),
            });
    }

    const getResultButton = (model: agentIdSearchModel, group: string): string =>
    {
        return `<button
                    type="button"
                    data-elementtype="agentIdSearchResultButton"
                    data-id="${model.Id}"
                    data-name="${model.Name}"
                    data-group="${group}"
                    class="btn btn-info btn-block">
                    ${model.Name} #${model.Id}
               </button>`;
    }

    const searchAgentsSuccess = (result: Array<agentIdSearchModel>) =>
    {
        if (result.length)
        {
            var group = $divAgentIdSearch.data("group");

            $spanAgentIdSearchResults.empty();

            for (var index = 0; index < result.length; index++)
            {
                $spanAgentIdSearchResults.append(getResultButton(result[index], group));
            }

            $("button[data-elementtype='agentIdSearchResultButton']").on("click", agentIdSearchResultButtonClick);
        }
        else
        {
            $spanAgentIdSearchResults.html("No results");
        }
    }

    const agentIdSearchResultButtonClick = (event: JQueryEventObject) =>
    {
        var $agentIdSearchResultButton = $(event.target);

        var id = $agentIdSearchResultButton.data("id");

        var name = $agentIdSearchResultButton.data("name");

        var group = $agentIdSearchResultButton.data("group");

        $(`input[data-elementtype='agentIdSearchValueInput'][data-group='${group}']`).val(id);
        $(`input[data-elementtype='agentIdSearchNameInput'][data-group='${group}']`).val(name);

        $divAgentIdSearch.hide();
    }

    const searchAgentsError = (textStatus: string) =>
    {
        if (textStatus != "abort")
        {
            Lobibox.alert(
                'error',
                {
                    title: 'Internet Issues',
                    msg: 'Sorry, we were not able to search agents, you may be experiencing an issue with your internet connection.',
                    delay: false,
                    position: 'top left',
                    buttons: {
                        custom: {
                            'class': 'btn btn-go',
                            text: 'Retry',
                            closeOnClick: 'true',
                        },
                        cancel: {
                            'class': 'btn btn-warning',
                            text: 'Cancel',
                            closeOnClick: true
                        },
                    },
                    callback: function (lobibox, type)
                    {
                        if (type === 'custom')
                        {
                            searchAgents();
                        }
                    }
                }
            );
        }
    }

    interface agentIdSearchModel
    {
        Id: number;
        Name: string;
    }

    const searchDiv: string = `
       <div id="divAgentIdSearch" style="display:none">
            <div class="modal" tabindex="-1" role="dialog" style="display:block">
                <div class="modal-dialog .modal-dialog-centered" role="document">
                    <div class="modal-content">
                        <div class="modal-header">

                            <h4 class="modal-title">Search Agents</h4><br />

                            <button type="button" class="close" id="btnAgentIdSearchClose" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>

                        </div>
                        <div class="modal-body">
                            <div><input id="txtAgentIdSearchTerm"></input></div>
                            <p id="spanAgentIdSearchResults"></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    `;

    $(document).ready(documentReady);
    $(document).ajaxComplete(documentAJAXComplete);
}